<!-- =========================================================================================
    File Name: ItemListView.vue
    Description: Item Component - List VIew

========================================================================================== -->

<template>
  <vx-card v-on="$listeners" class="app-card border-0" @click="$router.push(app.launchPath)">
    <div slot="no-body" style="cursor: pointer">
      <img
        :src="app.thumbnail"
        alt="content-img"
        class="responsive rounded-lg"
        draggable="false"
        style="width: 100%; height: 100%; object-fit: cover"
      />
    </div>
  </vx-card>
</template>

<script>
export default {
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-card {
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
