<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics

========================================================================================== -->

<template>
  <div>
    <div class="vx-row" style="height: 30.5rem" id="dashboard-home">
      <div class="vx-col w-3/4" id="left-column">
        <div class="flex gap-6 flex-col h-full justify-between">
          <video-background
            src="https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/dashboard-vbg.mp4"
            class="rounded-lg main-border"
            style="
              flex-grow: 1;
              min-height: 1em;
              height: 15em;
              background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/dashboard-placeholder-bg.png);
            "
          >
            <div
              style="backdrop-filter: blur(45px); height: 100%"
              class="knowledge-base-jumbotron-content rounded-lg p-8 lg:p-12 flex flex-col justify-center items-start"
            >
              <h1 class="text-5xl" id="welcome-back">Welcome Back, {{ activeUserInfo.firstName }}</h1>
              <h4 class="text-dark">Intellectual Point Learn</h4>
            </div>
          </video-background>

          <div class="grid grid-cols-3 gap-4 info-cards">
            <div class="main-border rounded-lg h-full">
              <div class="flex items-center gap-x-3 p-4 pb-3 main-bottom-border">
                <font-awesome-icon icon="fa-solid fa-wand-magic-sparkles" class="h-8 w-8 text-primary" />
                <h4>Learn Is Now... Really Learn</h4>
              </div>

              <p class="px-4 py-3 text-dark">
                Expect some changes over the next couple of weeks while we upgrade our systems. We're working on your new dashboard! Have an
                idea? Use the feedback tool to submit it to us! Any suggestions are greatly appreciated and valued.
              </p>
            </div>

            <div class="main-border rounded-lg" id="playground-card">
              <div class="flex items-center gap-x-3 p-4 pb-3 main-bottom-border">
                <font-awesome-icon icon="fa-solid fa-cubes" class="h-8 w-8 text-success" />
                <h4>Learn Speed Changes</h4>
              </div>

              <p class="px-4 py-3 text-dark">
                The learn platform is now really fast, like really fast, then the previous version, and is continuing to be worked on. For
                techincal support please email dev@intellectualpoint.com. We appreciate and welcome all feedback.
              </p>
            </div>

            <div class="main-border rounded-lg" id="feedback-card">
              <div class="flex items-center gap-x-3 p-4 pb-3 main-bottom-border">
                <font-awesome-icon icon="fa-solid fa-comments" class="h-8 w-8 text-warning" />
                <h4>We Need Your Feedback</h4>
              </div>

              <p class="px-4 py-3 text-dark">
                Have an idea or spotted a bug? Report it to us using the new feedback tool located at the top of this page. We will provide
                responses and updates pertaining to your feedback as soon as possible. Your feedback is greatly appreciated!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-col w-1/4" id="right-column">
        <component :is="courseProgressWidget" ref="courseProgressWidget" />
      </div>
    </div>

    <!-- <div id="quick-learn">
      <component :is="quickLearnWidget" ref="quickLearnWidget" />
    </div> -->
  </div>
</template>

<script>
import AppLauncher from '../platform/apps/launcher/AppLauncher';
import LottieAnimation from '../../components/lottie/LottieAnimation';
import VideoBackground from 'vue-responsive-video-background-player';

export default {
  data() {
    return {
      //
    };
  },
  components: {
    AppLauncher,
    LottieAnimation,
    VideoBackground,
  },
  methods: {
    navigateToDetailView() {
      this.$router.push({ name: 'app-simulation-starter', params: { simulationId: this.item.id } }).catch(() => {});
    },
    handleImageError(event) {
      event.target.src = this.placeholderImage;
    },
    logout() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Logout',
        text: 'Please confirm you want to logout of your accounts.',
        accept: this.confirmLogout,
        acceptText: 'Logout',
      });
    },
    confirmLogout() {
      localStorage.removeItem('sessionToken');
      localStorage.removeItem('protectionKey');
      localStorage.removeItem('userInfo');
      this.$router.push('/login');
    },
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
    courseProgressWidget() {
      // eslint-disable-next-line
      return () => import(`./DashboardCourseProgress.vue`);
    },
    quickLearnWidget() {
      // eslint-disable-next-line
      return () => import(`./DashboardQuickLearn.vue`);
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 719px) {
  .info-cards {
    display: none !important;
    margin-top: 2rem;
    flex-direction: column;
  }

  #dashboard-home {
    flex-direction: column;
    height: unset !important;
  }

  #left-column {
    width: unset !important;
  }

  #right-column {
    width: 100% !important;
    margin-top: 2rem;
  }

  #quick-learn {
    display: none;
  }

  #feedback-card {
    display: none;
  }

  #welcome-back {
    font-size: 2.5rem !important;
  }
}

@media only screen and (min-width: 720px) and (max-width: 992px) {
  .info-cards {
    display: flex !important;
    margin-top: 2rem;
  }

  #dashboard-home {
    flex-direction: column;
    height: unset !important;
  }

  #left-column {
    width: unset !important;
  }

  #right-column {
    width: 100% !important;
    margin-top: 2rem;
  }

  #quick-learn {
    display: none;
  }

  #feedback-card {
    display: none;
  }
}

@media only screen and (min-width: 1620px) and (max-width: 1699px) {
  #playground-card {
    display: block !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1700px) {
  #left-column {
    width: 63% !important;
  }

  #right-column {
    width: 37% !important;
  }

  #dashboard-home {
    height: unset !important;
  }

  #feedback-card {
    display: none;
  }

  .info-cards {
    display: flex !important;
    height: 42%;
  }

  #playground-card {
    display: none;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1850px) {
  #dashboard-home {
    height: 32.5rem !important;
  }
}
</style>
