<!-- =========================================================================================
    File Name: KnowledgeBase.vue
    Description: Knowledge Base Page

========================================================================================== -->

<template>
  <div>
    <div
      class="rounded-lg w-full"
      style="
        background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/global-banner.png);
        background-size: cover;
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
      "
    >
      <div class="knowledge-base-jumbotron-content lg:p-12 sm:p-10 p-8 rounded-lg mb-8" style="height: 100%; border: 1px solid #181d2a">
        <h5 class="font-light text-dark">Apps Launcher</h5>
        <h1 class="mb-1 text-white font-bold" id="app-launcher-header" style="font-size: 2.9rem">Select an App to Launch</h1>
      </div>
    </div>
    <div v-if="apps.length">
      <slide-x-left-transition group class="w-full h-full grid gap-x-4" :class="cardGridClass" id="app-cards-grid" tag="div">
        <div v-for="app in apps" :key="app.id" class="vx-col mb-base w-full">
          <app-card :app="app" />
        </div>
      </slide-x-left-transition>
    </div>
    <div class="simple-spinner" v-else>
      <span></span>
    </div>
  </div>
</template>

<!--suppress ES6ShorthandObjectProperty -->
<script>
import AppCard from './components/AppCard';

export default {
  data() {
    return {
      apps: [],
      noApps: false,
    };
  },
  components: {
    AppCard,
  },
  methods: {
    fetchAppsList() {
      this.apps = [];

      if (this.activeCourseInfo.selected === false) {
        this.$vs.notify({
          title: 'No Course Selected',
          text: 'Please select a course before practicing',
          color: 'danger',
          position: 'top-right',
        });

        this.$router.push('/courses');

        return;
      }

      if (!this.activeCourseInfo.appsEnabled.length) {
        this.noApps = true;
        return;
      }

      this.activeCourseInfo.appsEnabled.forEach((app, index) => {
        setTimeout(() => {
          this.apps.push(app);
        }, index * 60);
      });
    },
  },
  beforeMount() {
    this.fetchAppsList();
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
    cardGridClass() {
      const size = Math.min(Math.max(this.activeCourseInfo.appsEnabled.length, 3), 5);

      return `grid-cols-${size}`;
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 1300px) {
  #app-cards-grid {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (max-width: 750px) {
  #app-cards-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  #app-launcher-header {
    font-size: 2rem !important;
  }
}
</style>
